import ReactPlayer from "react-player";
import portalVideoUrl from "./static/portalVideo.mp4";
import instructions from "./static/instructions.png";
import "react-step-progress-bar/styles.css";
// @ts-ignore
import { ProgressBar } from "react-step-progress-bar";
import "./LoadingGallery.scss";
import { useEffect, useState } from "react";
import { useScreenSize } from "./hooks/useScreenSize";

const LoadingGallery = ({ onComplete }: { onComplete?: () => void }) => {
  const { innerWidth } = useScreenSize();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let localCounter = 0;
    setInterval(() => {
      localCounter += 5 + Math.random() * 10;
      if (localCounter <= 100) {
        setCounter(localCounter);
      } else if (localCounter > 100) {
        if (onComplete) {
          onComplete();
        }
        setCounter(100);
      }
    }, 300);
  }, []);

  return (
    <div className="loadingGalleryView">
      <div className="loadingGalleryView-center">
        <div className="loadingGalleryView-player">
          <ReactPlayer
            width={innerWidth}
            height={innerWidth * 0.5625}
            playing
            loop
            volume={0}
            muted
            url={portalVideoUrl}
          />
        </div>
        <div className="loadingGalleryView-content">
          <img
            className="loadingGalleryView-content-logo"
            src={instructions}
          ></img>
          <h2 className="loadingGalleryView-content-title">
            Please use Keyboard & Mouse for best experience
          </h2>
          <div className="loadingGalleryView-content-progress">
            <ProgressBar
              percent={counter}
              filledBackground="#FF00E5"
              unfilledBackground="#FFFFFF"
            />
          </div>
          <p className="loadingGalleryView-content-counter">
            {Math.ceil(counter)}%
          </p>
          <p className="loadingGalleryView-content-text">Loading Art</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingGallery;
