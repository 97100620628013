import { useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./ArtistView.scss";
import classNames from "classnames";
import Disqus from "disqus-react";
import CommentsSvg from "./static/comments.svg";
import LogoBlack from "./static/logoBlack.png";
import close from "./static/close.png";
import { Element, scroller } from "react-scroll";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

export type Screen = {
  id: string;
  name: string;
  url: string;
  type: string;
  description: string;
  vimeoVideo: string;
  youtubeVideo: string;
};

export type Room = {
  files: Array<Screen>;
  title: string;
  artistName: string;
  artistPortrait: string;
  artistDescription: string;
};

const ArtistView = ({
  roomId,
  onClose,
  sendMessage,
  screenId,
}: {
  roomId: string;
  onClose?: () => void;
  sendMessage?: (m: string, a: string) => void;
  screenId: string;
}) => {
  const [room, setRoom] = useState<Room>();
  const [discussion, setDiscussion] = useState<string>("");

  useEffect(() => {
    fetch("https://stoff-test-nodejs.azurewebsites.net/room?id=" + roomId)
      .then((response) => response.json())
      .then((data: Room) => setRoom(data));
  }, [roomId]);

  useEffect(() => {
    window.setTimeout(() => {
      scroller.scrollTo(screenId, {
        containerId: "container",
        duration: 1500,
        delay: 100,
        smooth: "easeInOutQuint",
      });
    }, 100);
  }, [room, screenId, scroller]);

  return (
    <div className="artistView" id="container">
      <div className="artistView-header">
        <img src={LogoBlack} className="artistView-header-logo" />
        <img
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          src={close}
          className="artistView-header-close"
        />
      </div>
      <div className="artistView-container">
        {room && <h1 className="artistView-heading">{room.artistName}</h1>}
        <div className="artistView-grid">
          {room?.files.map((screen, i) => (
            <Element name={screen.id} key={screen.id + i}>
              <div className="artistView-grid-row">
                <Screen
                  screen={screen}
                  alignRight={i % 2 != 0}
                  roomTitle={room.title}
                  discussion={discussion}
                  updateDiscussion={() => {
                    setDiscussion(screen.id);
                  }}
                />
                {i === 0 && (
                  <div className="artistView-artist">
                    <img src={room.artistPortrait} />
                    {room.artistDescription && <p>{room.artistDescription}</p>}
                  </div>
                )}
              </div>
            </Element>
          ))}
        </div>
      </div>
    </div>
  );
};

const Screen = ({
  screen,
  alignRight,
  roomTitle,
  discussion,
  updateDiscussion,
}: {
  screen: Screen;
  alignRight: boolean;
  roomTitle: string;
  discussion: string;
  updateDiscussion: () => void;
}) => {
  let youtubeUrl = "";
  if (screen.youtubeVideo) {
    const regex = /v=\S+/g;

    const found = screen.youtubeVideo.match(regex);
    youtubeUrl = found?.[0].slice(2) ?? "";
  }

  let vimeoUrl = "";
  if (screen.vimeoVideo) {
    const regex = /\/\d+/g;
    const found = screen.vimeoVideo.match(regex);
    vimeoUrl = found?.[0]?.slice(1) ?? "";
  }

  if (
    screen.type === "empty" ||
    (screen.type === "video" && !(youtubeUrl || vimeoUrl))
  ) {
    return null;
  }

  return (
    <div
      className={classNames("artistView-screen", {
        "artistView-screen-right": alignRight,
      })}
    >
      {screen.type === "image" && (
        <img style={{ width: "100%" }} src={screen.url}></img>
      )}
      {screen.type === "video" && screen.vimeoVideo && (
        <iframe
          style={{ aspectRatio: "16/9" }}
          src={`https://player.vimeo.com/video/${vimeoUrl}`}
          width="100%"
          // height="500px"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      {screen.type === "video" && !screen.vimeoVideo && screen.youtubeVideo && (
        <iframe
          style={{ aspectRatio: "16/9" }}
          width="100%"
          src={`https://www.youtube.com/embed/${youtubeUrl}`}
          title="YouTube video player"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}

      <h2>{screen.name}</h2>
      {/* <p>{documentToReactComponents(screen.description as any)}</p> */}
      {/* <p>{screen.description}</p> */}
      {/* <p>{screen.description}</p> */}
      <ReactMarkdown
        children={screen.description}
        remarkPlugins={[remarkGfm]}
      />

      {discussion === screen.id ? (
        <Disqus.DiscussionEmbed
          shortname={"arenastoff"}
          config={{
            url: "https://arenastoff.com/#!" + screen.id,
            identifier: screen.id + "_0",
            title: `${roomTitle} - ${screen.name}`,
          }}
        />
      ) : (
        <div className="artistView-comments" onClick={updateDiscussion}>
          <p>Show Comments</p>
          <img className="artistView-comments-bubble" src={CommentsSvg}></img>
        </div>
      )}
    </div>
  );
};

export default ArtistView;
