import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ScreenDisplay from "./ScreenDisplay";
import Loading from "./Loading";
import ArtistView from "./ArtistView";
import AboutUs from "./AboutUs";
import Promotion from "./Promotion";
import { useScreenSize } from "./hooks/useScreenSize";
import Mobile from "./Mobile";
import LoadingGallery from "./LoadingGallery";

const App = () => {
  const { unityProvider, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      loaderUrl: "/unity/unity.loader.js",
      dataUrl: "/unity/unity.data",
      frameworkUrl: "/unity/unity.framework.js",
      codeUrl: "/unity/unity.wasm",
    });

  const unityRef = useRef<HTMLCanvasElement>(null);

  const [showLoading, setShowLoading] = useState(true);
  const [showLoadingGallery, setShowLoadingGallery] = useState(false);
  const [showArtistView, setShowArtistView] = useState(false);
  // const [galleryMenu, setGalleryMenu] = useState(false);
  const [screenDisplay, setScreenDisplay] = useState(false);
  const [screenId, setScreenId] = useState<string>("");
  const [roomId, setRoomId] = useState<string>("");
  const [aboutUs, setAboutUs] = useState(false);

  const { innerWidth } = useScreenSize();

  const handleOverlay = useCallback((message: string, arg: string) => {
    if (message === "Open Lab") {
      if (window) {
        window.open("https://www.bombinabombast.com/", "_blank", "noreferrer");
      }
    }
    if (message === "Open Screen") {
      setScreenId(arg);
      setScreenDisplay(true);
    }
    if (message === "Load Gallery") {
      setRoomId(arg);
      setShowLoadingGallery(true);
    }
    if (message === "About Us") {
      setAboutUs(true);
    }
  }, []);

  useEffect(() => {
    addEventListener("Overlay", handleOverlay);
    return () => {
      removeEventListener("Overlay", handleOverlay);
    };
  }, [addEventListener, removeEventListener, handleOverlay]);

  const sendAMessage = () => {
    sendMessage("GameController", "ReturnToUnity");
  };

  useEffect(() => {
    if (unityRef.current) {
      unityRef.current.addEventListener("click", () => {
        sendAMessage();
      });
    }
  });

  if (window.innerWidth < 800) {
    return <Mobile />;
  }

  return (
    <div className="App">
      {showLoading && <Loading onComplete={() => setShowLoading(false)} />}
      {showLoadingGallery && (
        <LoadingGallery
          onComplete={() => {
            setShowLoadingGallery(false);
          }}
        />
      )}
      <div className="centerUnity" onFocus={() => {}}>
        <Unity
          unityProvider={unityProvider}
          style={{
            width: innerWidth,
          }}
          ref={unityRef}
        />
      </div>
      {showArtistView && (
        <ArtistView
          roomId={roomId}
          sendMessage={sendAMessage}
          screenId={screenId}
          onClose={() => {
            setShowArtistView(false);
            sendAMessage();
          }}
        />
      )}
      {screenDisplay && (
        <ScreenDisplay
          onClose={() => {
            setScreenDisplay(false);
            sendAMessage();
          }}
          roomId={roomId}
          assetId={screenId}
          onReadMore={() => {
            setShowArtistView(true);
            setScreenDisplay(false);
          }}
        />
      )}
      {aboutUs && (
        <AboutUs
          onClose={() => {
            sendAMessage();
            setAboutUs(false);
          }}
        />
      )}
    </div>
  );
};

export default App;
