import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ArtistView from "./ArtistView";
import Loading from "./Loading";
import ScreenDisplay from "./ScreenDisplay";
import AboutUs from "./AboutUs";
import Mobile from "./Mobile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  // {
  //   path: "artist",
  //   element: <ArtistView roomId="6nv3bPLgD3z3azQbIojHbs" screenId="test" />,
  // },
  // {
  //   path: "mobile",
  //   element: <Mobile />,
  // },
  // {
  //   path: "loading",
  //   element: <Loading />,
  // },
  // {
  //   path: "about",
  //   element: <AboutUs />,
  // },
  // {
  //   path: "screen",
  //   element: (
  //     <ScreenDisplay
  //       assetId="QjSmUMDvO7znE88a6X6o2"
  //       roomId="2xjfNw6DaWttDuSBk8PFkP"
  //     />
  //   ),
  // },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
