import ReactPlayer from "react-player";
import portalVideoUrl from "./static/portalVideo.mp4";
import Logo from "./static/logo.png";
import "react-step-progress-bar/styles.css";
// @ts-ignore
import { ProgressBar } from "react-step-progress-bar";
import "./Loading.scss";
import { useEffect, useState } from "react";
import { useScreenSize } from "./hooks/useScreenSize";

const Loading = ({ onComplete }: { onComplete?: () => void }) => {
  const { innerWidth } = useScreenSize();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let localCounter = 0;
    setInterval(() => {
      localCounter += 5 + Math.random() * 10;
      if (localCounter <= 100) {
        setCounter(localCounter);
      } else if (localCounter > 100) {
        if (onComplete) {
          onComplete();
        }
        setCounter(100);
      }
    }, 600);
  }, []);

  return (
    <div className="loadingView">
      <div className="loadingView-center">
        <div className="loadingView-player">
          <ReactPlayer
            width={innerWidth}
            height={innerWidth * 0.5625}
            playing
            loop
            volume={0}
            muted
            url={portalVideoUrl}
          />
        </div>
        <div className="loadingView-content">
          <img className="loadingView-content-logo" src={Logo}></img>
          <h2 className="loadingView-content-title">
            Welcome to the virtual home of real arts
          </h2>
          <div className="loadingView-content-progress">
            <ProgressBar
              percent={counter}
              filledBackground="#FF00E5"
              unfilledBackground="#FFFFFF"
            />
          </div>
          <p className="loadingView-content-counter">{Math.ceil(counter)}%</p>
          <p className="loadingView-content-text">
            Constructing Digital Landscape...
          </p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
