import "./ScreenDisplay.scss";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import close from "./static/close.png";
import { Room } from "./ArtistView";

const ScreenDisplay = ({
  assetId,
  onReadMore,
  onClose,
  roomId,
}: {
  assetId: string;
  onReadMore?: () => void;
  onClose?: () => void;
  roomId?: string;
}) => {
  const [screen, setScreen] = useState<any>();
  const [room, setRoom] = useState<Room>();

  useEffect(() => {
    fetch("https://stoff-test-nodejs.azurewebsites.net/room?id=" + roomId)
      .then((response) => response.json())
      .then((data: Room) => setRoom(data));
  }, [roomId]);

  useEffect(() => {
    fetch("https://stoff-test-nodejs.azurewebsites.net/screen?id=" + assetId)
      .then((response) => response.json())
      .then((data) => setScreen(data));
    // .then((data) => console.log(data));
  }, [assetId]);

  let youtubeUrl = "";
  if (screen?.youtubeVideo) {
    const regex = /v=\S+/g;

    const found = screen.youtubeVideo.match(regex);
    youtubeUrl = found?.[0].slice(2) ?? "";
  }

  let vimeoUrl = "";
  if (screen?.vimeoVideo) {
    const regex = /\/\d+/g;
    const found = screen.vimeoVideo.match(regex);
    vimeoUrl = found?.[0]?.slice(1) ?? "";
  }

  // if (
  //   screen.type === "empty" ||
  //   (screen.type === "video" && !(youtubeUrl || vimeoUrl))
  // ) {
  //   return null;
  // }

  return (
    <motion.div
      className="screenDisplay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.4,
        ease: "easeIn",
      }}
    >
      {screen && (
        <div className="screenDisplay-container">
          <div className="screenDisplay-content">
            <h2 className="screenDisplay-room">{room?.title}</h2>
            {screen.type === "image" && (
              <img style={{ width: "100%" }} src={screen.url}></img>
            )}
            {screen.type === "video" && screen.vimeoVideo && (
              <div className="videoContainer">
                <iframe
                  className="vimeoVideo"
                  src={`https://player.vimeo.com/video/${vimeoUrl}`}
                  width="100%"
                  // height="500px"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            {screen.type === "video" &&
              !screen.vimeoVideo &&
              screen.youtubeVideo && (
                <div className="videoContainer">
                  <iframe
                    className="vimeoVideo"
                    style={{ aspectRatio: "16/9" }}
                    width="100%"
                    src={`https://www.youtube.com/embed/${youtubeUrl}`}
                    title="YouTube video player"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

            {/* <p className="screenDisplay-text">{screen.description}</p> */}
            <div className="screenDisplay-under">
              <h2 className="screenDisplay-heading">{screen.name}</h2>
              <h2 className="screenDisplay-more" onClick={onReadMore}>
                More info
              </h2>
            </div>
            <img
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              src={close}
              className="screenDisplay-close"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ScreenDisplay;
