import "./AboutUs.scss";
import ReactPlayer from "react-player";
import portalVideoUrl from "./static/portalVideo.mp4";
import Logo from "./static/logo.png";
import "react-step-progress-bar/styles.css";
import close from "./static/close.png";
import { motion } from "framer-motion";
import { useScreenSize } from "./hooks/useScreenSize";

const AboutUs = ({ onClose }: { onClose?: () => void }) => {
  const { innerWidth } = useScreenSize();

  return (
    <motion.div
      className="about"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.4,
        ease: "easeIn",
      }}
    >
      <div className="about-center">
        <div className="about-player">
          <ReactPlayer
            width={innerWidth}
            height={innerWidth * 0.5625}
            playing
            loop
            volume={0}
            muted
            url={portalVideoUrl}
          />
        </div>
        <img
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          src={close}
          className="about-close"
        />
        <div className="about-content">
          <img className="about-content-logo" src={Logo}></img>
          <h2 className="about-content-title">
            Welcome to the virtual home of real arts
          </h2>
          <p className="about-content-text">
            ARENA STOFF offers you a possibility to meet and experience work
            created by local and international artists. The platform will
            present a wide range of artistic formats and genres available to a
            broad spectrum of audiences. Whilst launching the first edition of
            this new culture hub, we're also laying the foundation for not yet
            established genres and arts innovations that may come our way in the
            near future.
            <br />
            <br />
            Check out our current exhibitions and performance offering, share
            your thoughts via the comments section and deep dive into the
            creative minds of the displayed talent.
            <br />
            <br />
            Visitors who create their own profiles are able to leave comments
            and create a digital footprint whilst making an impact on the
            artist's work and their cause.
            <br />
            Experience arts from all over the globe - Catch live performances -
            Network and share some love in our comments section - Produce and
            exhibit - Market to a new and global audience - Acquire the new and
            the unique.
            <br />
            <br />
            Check out the community notice board to learn of other digital and
            IRL places and spaces that elevate similar artists and events!
            <br />
            <br />
            Your navigation around ARENA STOFF is mapped by our heatmap floor.
            The more time you spend in front of a particular work, the “hotter”
            that spot becomes, thus signalling to other visitors where there has
            been some action. Your official footprint is not connected to your
            personal data.
            <br />
            <br />
            #ARENAStoff is made possible with support from Kulturbryggan,
            Kulturrådet, Kultur Stockholms stad & Kulturförvaltningen Region
            Stockholm. TACK/THANK YOU!
            <br />
            <br />
            <a href="mailto:speak@arenastoff.com">speak@arenastoff.com</a>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
