import ReactPlayer from "react-player";
import portalVideoUrl from "./static/portalVideo.mp4";
import Logo from "./static/logo.png";
import "react-step-progress-bar/styles.css";
import "./Mobile.scss";
import { useScreenSize } from "./hooks/useScreenSize";

const Mobile = () => {
  const { innerHeight } = useScreenSize();
  return (
    <div className="mobile">
      <div className="mobile-center">
        <div className="mobile-player">
          <ReactPlayer
            width={innerHeight * 1.7}
            height={innerHeight}
            playing
            loop
            volume={0}
            muted
            url={portalVideoUrl}
          />
        </div>
        <div className="mobile-content">
          <img className="mobile-content-logo" src={Logo}></img>
          <h2 className="mobile-content-title">
            Welcome to the virtual home of real arts
          </h2>
          <p className="mobile-content-text">
            This is a Beta-version, please use a laptop or desktop computer for
            the full experience
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
